<div class="container" *ngIf="blmEvents">
  <span class="gap"></span>
  <h1 [innerText]="blmEvents.fields.title"></h1>
  <div class="row">
      <div class="col-lg-6">
          <img class="img-fluid" [src]="blmEvents.fields.images[0].fields.file.url" [alt]="blmEvents.fields.images[0].fields.file.title">
      </div>
      <span class="gap"></span>
      <div class="card col-lg-6">
          <span class="gap"></span>
        
          <p [innerHTML]="blmEvents.fields.longDescription | mdToHtml"></p>
      </div>
  </div>
</div>
