<router-outlet></router-outlet>

<footer class="text-center">
    <div>
      <span lass="d-inline-block" tabindex="0" data-toggle="tooltip" title="Click for Directions">
      <i class="fas fa-route"></i>&nbsp;<a class="text-dark" href="https://goo.gl/maps/ScmJEb1U5wZ97o9DA">61115 S St. Mary Street, Lacombe, LA 70445</a>
      &nbsp; &nbsp; Thursday, Friday & Saturday: Noon - 4 p.m
    </span>
    </div>
    
    <br>
   <p> &copy; {{ year }} Bayou Lacombe Museum powered by <span><a class="text-dark" href="https://cypressbayoumedia.com/">Cypress Bayou Media</a></span></p>
  </footer>
  <span class="gap"></span>